// $primary: #a51616;
$primary: #00adb9;
/* MAIN COLOR */
$secondary: #00adb9;
/* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


h1, h2, h3 {font-family: 'Titan One', cursive;}

a, p, ul, ol {font-family: 'Trocchi', serif;}

.row {margin-right: 0px;
	  margin-left: 0px;
}


.col-md-6-1024 {width: 50%;

				@media (max-width: 1024px) {
					width: 100%;
				}
}


.flash {
	display:none;
}

nav {
	z-index: 1000;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.navbar-default {border-bottom: 2px solid $primary;
				background: url('../img/header-bg.png');
				background-repeat: repeat;}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 120px;
		display: flex;
		align-items: center;
		border: 0px;
		color: $primary;
		text-shadow: 2px 2px $secondary;
		padding: 10px 13px;

		@media (max-width: 767px) {
		    height: 20px;
		    display: inline-block;
			margin: 0px;
		}

	    &:hover, &:focus {
	    	color: $secondary;
			text-shadow: 1px 1px $primary;
			outline: 0px;
	    }
	}
}


.navbar-right {
	margin-top: 0px;
	font-size: 1.75em;
	font-weight: bold;
	letter-spacing: 2px;
	text-transform: uppercase;
	z-index: 1;

	@media (max-width: 1024px) {
		font-size: 1.3em;
		margin-top: 0px;
		text-align: center;
	}

	@media (max-width: 991px) {
		font-size: 1.3em;
		margin-top: 0px;
		letter-spacing: 0px;
		text-align: right;
	}

	@media (max-width: 768px) {
		font-size: 1.25em;
		margin-top: 0px;
		letter-spacing: 0px;
		text-align: center;
	}
}


.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	background-color: $secondary;

	@media (max-width: 767px) {
		margin-top: 10px;
	}

}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}



/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $secondary;
    border-color: $wht;
	   color: $wht;
padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
	 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.btn-download:hover {
    background-color: $primary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/* ========== HEADER ============== */

.header {background-image: url('../img/banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 50% 10%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
  		 -webkit-background-size: cover;
 
	@media (max-width: 768px) {
		background-position: 40% 20%;
	}

}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.5);
  padding: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


    @media (max-width: 1024px) {
			padding: 0px;

	}

	@media (max-width: 1440px) {
			padding: 80px 0px;

	}


}

.header h1 {color: $wht;
			font-size: 5em;
			text-align: center;
			
			@media (max-width: 1024px) {

				font-size: 2em;
			}
}


.logo {
  width: 100%;
  max-width: 300px;
  margin-left: 10px;
  padding: 5px;

  @media (max-width: 991px) {
	  	   	width: 100%;
			max-width: 200px;
			margin-left: 0px;
			padding: 10px;
  }

  @media (max-width: 767px) {
	   	   width: 100%;
			max-width: 145px;
			margin-left: 0px;
			padding: 10px;
  }  

}

  .header img {display: block;
			 margin: 0 auto;
			 width: 100%;
			 max-width: 600px;
			 
			@media (max-width: 1024px) {
				max-width: 320px;
			}
			 
			 }


/* ============ CTA ============ */

.cta {background: url('../img/header-bg.png');
	  background-repeat: repeat;
	  padding: 50px 0px;
	  
	  @media (max-width: 767px) {
		  padding: 0px;
	  }
	  
	  }


.cta h1 {font-size: 4em;
		 color: $primary;
		 padding-bottom: 25px;
		 
		 @media (max-width: 767px) {
			 font-size: 3em;
		 }
		 
		 }

.cta p {font-size: 2em;
		color: $primary;}


			 
/* ========== MINIFORM AREA ============== */

.miniform {padding: 50px;

		@media (max-width: 1280px) {
			padding: 35px 0px;
			text-align: center;
		}


}

.miniform img {width: 100%;
			   display: block;
			   margin: 0 auto;
			   max-width: 650px;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			   }

.miniform h1 {font-size: 3.5em;
			  color: $primary;
			  padding: 10px;
			}

.miniform p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 40px;
			 color: $primary;
			 padding: 10px;}

/* ========== BENEFITS ============== */


.benefits {background-image: url('../img/lower-banner-1.jpg');
		   background-repeat: no-repeat;
		   background-position: 50% 0%;
		   background-size: cover;
		   -moz-background-size: cover;
		   -o-background-size: cover;
		   -webkit-background-size: cover;
		   text-align: center;


		   @media (max-width: 767px) {
			   background-position: 12% 0%;
		   }

}

.benefits-overlay {
  background-color: rgba(31, 31, 31, 0.75);
  padding: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 1280px) {
	  padding: 25px 0px;
	  text-align: center;
  }



}

.benefits h1 {font-size: 3.5em;
		      color: $wht;


			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
	}

.benefits p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;
			 color: $wht;}

.benefits img {width: 100%;
			   max-width: 550px;
			   display: block;
			   margin: 0 auto;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			
			@media (max-width: 767px) {
				  padding-top: 0px
			  }
			
			
			@media (max-width: 1024px) {
				  margin-top: 25px;
			  }

			@media (max-width: 1199px) {
				  margin-top: 60px
			  }
}


/* ========== FEATURES ============== */

.features {padding: 50px;
		   text-align: center;

			@media (max-width: 1280px) {
				padding: 25px 0px;
			}

}

.features h1 {font-size: 4em;
			  color: $primary;
			  text-align: center;

			  @media (max-width: 1024px) {
				  font-size: 2.25em;
			  }
			  
			  
			  }

.features h2 {font-size: 3em;
			  color: $primary;
			  
			  @media (max-width: 1024px) {
				  text-align: center;
			  }
			  
			  
			  }

.features p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 30px;
			 color: $primary;
			 margin-bottom: 50px;
			 
			@media (max-width: 1024px) {
				  text-align: center;
			  }
			 
			 
			 }

.features img {width: 100%;
			   max-width: 450px;
			   display: block;
			   margin: 0 auto;
			   border: 15px solid $secondary;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.72), 0 6px 20px 0 rgba(0, 0, 0, 0.72);


			   @media (max-width: 1024px) {
				   max-width: 250px;
			   }
		}


		/* ========== LOWER CTA ============== */


.lower-cta {background-image: url('../img/lower-cta-banner.jpg');
		   background-repeat: no-repeat;
		   background-position: 60% 0%;
		   background-size: cover;
		   -moz-background-size: cover;
		   -o-background-size: cover;
		   -webkit-background-size: cover;
		   text-align: center;

		   @media (max-width: 767px) {
			   background-position: 83% 0%;
		   }

}

.lower-cta-overlay {
  background-color: rgba(31, 31, 31, 0.75);
  padding: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 1280px) {
	  padding: 25px 0px;
	  text-align: center;
  }



}

.lower-cta h1 {font-size: 3.5em;
		      color: $wht;


			  @media (max-width: 1024px) {
				  font-size: 3em;
			  }
	}

.lower-cta p {font-size: 1.65em;
			 letter-spacing: 1px;
			 line-height: 40px;
			 padding: 10px;
			 color: $wht;}

.lower-cta img {width: 100%;
			   max-width: 550px;
			   display: block;
			   margin: 0 auto;
			   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			
			@media (max-width: 767px) {
				  padding-top: 0px
			  }
			
			
			@media (max-width: 1024px) {
				  margin-top: 25px;
			  }

			@media (max-width: 1199px) {
				  margin-top: 60px
			  }
}

.sticky-wrapper {height: auto !important;}

.navbar {margin-bottom: 0px;}